import { FunctionComponent } from 'react'

interface Props {
  label?: string
  input: any
  icon?: any
  optionSize?: number
  onClick?: Function
  setValue?: Function
  isPlannerComponent?: boolean
  isMultiSelect?: boolean
  nudgeThisOption?: boolean
  fieldValue?: string
}

const CustomInputSelectionCheckbox: FunctionComponent<Props> = ({
  label,
  input,
  icon,
  onClick,
  setValue,
  isPlannerComponent,
  isMultiSelect,
  nudgeThisOption,
  fieldValue,
}) => {
  return (
    <>
      <div className="flex justify-center items-center">
        <label className="flex md:py-2 w-full">
          <label
            className={`${
              isPlannerComponent && isMultiSelect && 'radio-label'
            } checkbox-label  m-9 w-full`}
            onClick={() => {
              if (setValue) {
                setValue(label)
              }
            }}
          >
            <input
              type={'checkbox'}
              {...input}
              onClick={onClick}
              className={`${isPlannerComponent ? 'checkbox-round' : ''}`}
            />
            <span className="checkbox-custom rectangular"></span>
            {label && (
              <div
                className={`bg-white rounded text-font14 text-center md:pr-4 md:pl-4 px-2 grid flex-col items-center bold input-reaction cursor-pointer ${
                  typeof icon == 'string' || isPlannerComponent
                    ? 'input-with-icon'
                    : `input-without-icon  ${
                        ['preferredCountry', 'preferredIntake'].includes(
                          fieldValue || '',
                        )
                          ? 'xs:w-[104px] w-[98px] '
                          : 'w-full'
                      }`
                }`}
              >
                {nudgeThisOption && (
                  <div
                    className="absolute font-bold bg-green-1900 px-2 md:px-3 rounded-lg flex justify-center w-[94px] md:w-[134px] left-0 right-0 mx-auto"
                    style={{ top: '-12px' }}
                  >
                    <p className="mr-1 text-green-1751 text-[10px] md:text-xs">
                      Recommended
                    </p>
                    <img src= {`${process.env.CDN_URL}/assets/icons/green_dot.svg`} alt="icon" />
                  </div>
                )}
                {icon && <img className="flag-img" src={icon} alt="logo" />}
                <p
                  className={`${
                    typeof icon == 'string' ? '' : 'md:py-0 py-0 my-auto mx-0'
                  } leading-none md:w-auto text-lightgray-800 text-center text-font14 md:text-font16 w-full ${
                    isPlannerComponent && 'whitespace-nowrap'
                  }`}
                >
                  {label}
                </p>
              </div>
            )}
          </label>
        </label>
        <style jsx>{`
          checkbox-container {
            float: left;
            width: 50%;
            box-sizing: border-box;
            text-align: center;
            padding: 90px 0px;

            border: 1px solid red;
          }

          .radio-label input {
            position: absolute;
            opacity: 1 !important;
            cursor: pointer;
            right: 0px;
            margin: 5px !important;
            transform: scale(1.1);
          }

          .checkbox-round {
            width: 0.7em;
            height: 0.7em;
            background-color: white;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #ddd;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
          }

          .checkbox-round:checked {
            background-position-x: -0.5px;
            background-image: url(process.env.CDN_URL + "/assets/images/planner/radio-tick.svg");
            background-color: #443eff;
          }

          .radio-label input[type='checkbox'] {
            border-radius: 50% !important;
            display: hidden !important;
          }

          /* Styling Checkbox Starts */
          .checkbox-label {
            display: block;
            position: relative;
            cursor: pointer;
            font-size: 22px;
            line-height: 24px;

            clear: both;
          }

          .checkbox-label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkbox-label .input-reaction {
            border: 1px solid #bdbdbd;
          }
          .checkbox-label input:checked ~ .input-reaction {
            background-color: #e3e4ff;
            border: 2px solid #494fe3;
          }
          .flag-img {
            height: 24px;
            width: 36.63px;
            margin: 0 auto;
          }

          @media only screen and (max-device-width: 768px) {
            .input-with-icon {
              width: 96px;
              height: 72px;
              padding: 10px 0px;
            }
          }
          @media only screen and (min-device-width: 769px) {
            .input-with-icon {
              width: 180px;
              height: 100px;
              padding: 16px 0px;
            }
          }
          @media only screen and (max-device-width: 768px) {
            .input-without-icon {
              /* width: 100%; */
              min-height: 48px;
            }
          }
          @media only screen and (min-device-width: 769px) {
            .input-without-icon {
              width: 180px;
              height: 48px;
            }
            .input-without-icon-planner {
              width: 180px;
              height: 120px;
            }
          }
        `}</style>
      </div>
    </>
  )
}

export default CustomInputSelectionCheckbox
