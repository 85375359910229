import CustomInputSelectionCheckbox from 'components/Inputs/CustomInputSelectionCheckbox'
import { FunctionComponent, useMemo } from 'react'
import { Field } from 'react-final-form'

interface Props {
  question: string
  subText?: string
  input: any
  label: string
  options: any
  meta: any
  className?: string
  isMultiSelect?: boolean
  numGridColumns?: number
  onClick?: Function
  setValue?: Function
  isPlannerComponent?: boolean
  maxSelect?: number
  nudgeOption?: { country: string; intake: string }
  fieldValue?: string
}
const CustomInputSelect: FunctionComponent<Props> = ({
  input,
  options,
  meta,
  isMultiSelect,
  subText,
  numGridColumns,
  onClick,
  setValue,
  isPlannerComponent,
  nudgeOption,
  fieldValue,
}) => {
  const gridColCssStr = useMemo(
    () =>
      numGridColumns
        ? `grid-cols-2 md:grid-cols-${numGridColumns} ${
            isPlannerComponent ? 'w-fit' : ''
          }`
        : ['preferredCountry', 'preferredIntake'].includes(fieldValue || '')
        ? ''
        : 'grid-cols-3',
    [numGridColumns, fieldValue],
  )
  return (
    <>
      <div className="text-lightgray-800 text-font14 md:text-base">
        {subText}
      </div>
      <div
        className={`${
          ['preferredCountry', 'preferredIntake'].includes(fieldValue || '')
            ? 'flex flex-wrap justify-center'
            : 'grid'
        } ${gridColCssStr}`}
      >
        {options.map((option: any, i: number) => {
          const { label, value, icon } = option
          return (
            <label
              key={i}
              className={
                isPlannerComponent
                  ? `flex justify-center align-middle items-center`
                  : `"mx-auto ${
                      numGridColumns == 2 ? 'md:first:justify-self-end' : ''
                    }`
              }
            >
              <Field
                name={input.name}
                component={CustomInputSelectionCheckbox}
                type={isMultiSelect ? 'checkbox' : 'radio'}
                value={value}
                isMultiSelect={isMultiSelect}
                label={label}
                icon={icon}
                isPlannerComponent={isPlannerComponent}
                optionSize={options.length}
                onClick={onClick}
                setValue={setValue}
                nudgeThisOption={
                  nudgeOption ? nudgeOption.intake == value : false
                }
                fieldValue={fieldValue}
              />
            </label>
          )
        })}
      </div>
      {meta.touched && meta.error && (
        <p
          className={`mt-1 text-xs text-red-600 ${
            isPlannerComponent ? `md:pl-8 pl-4` : `md:pl-2 pl-4`
          }`}
        >
          {meta.error}
        </p>
      )}
    </>
  )
}

export default CustomInputSelect
