import useDevice from "hooks/useDevice"
import { FunctionComponent, ReactNode } from "react"

interface InputProps {
  label?: string
  disabled?: boolean
  isRequired?: boolean
  placeholder?: string | ReactNode
  meta?: any
  rest?: any
  input?: any
  helperText?: string
  icon?: any
  isNewOnboardingExp?: boolean
  max?: string
  min?: string
  onFocus?: () => void
  onBlur?: () => void
  inputCss?: string
}

const TextInput: FunctionComponent<InputProps> = ({
  input,
  meta,
  label,
  disabled,
  placeholder,
  helperText,
  icon,
  isNewOnboardingExp,
  min,
  max,
  onFocus,
  onBlur,
  inputCss = "",
}) => {
  const { isIos } = useDevice()
  const hasError = meta && meta.touched && meta.error
  const value = input && input.value !== ""
  const name = input && input.name
  return (
    <div className={`relative w-full ${isNewOnboardingExp ? "px-6" : ""}`}>
      <div className={value && !isNewOnboardingExp ? "is-filled !w-full" : ""}>
        <input
          id={`field-${name}`}
          className={`h-12 relative px-4 w-full border-2 bg-white ${
            isNewOnboardingExp
              ? " focus:!border-[#BBAAEC] bg-transparent border-2 border-[#BBAAEC] placeholder:!text-[#686868] !text-white"
              : "pt-1"
          } rounded-lg text-base leading-tight ${
            hasError ? "border-red-600" : "border-[#CDCDCD]"
          } ${inputCss}`}
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          min={min || ""}
          max={max || ""}
          {...input}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{ WebkitAppearance: "none", textAlignLast: "start" }}
        />
        {isIos && !input?.value && input?.type === "date" && (
          <p className=" absolute top-[50%] translate-y-[-50%] pl-4 text-[#686868]">
            {placeholder}
          </p>
        )}
        {!isNewOnboardingExp && (
          <label
            className={`inputLabel absolute text-xxs inline-block px-4 ${
              isNewOnboardingExp
                ? "text-white left-[24px]"
                : "text-black left-0 "
            } opacity-0 cursor-auto top-0 mt-1 md:mt-2`}
            htmlFor={`field-${name}`}
          >
            {label}
          </label>
        )}
      </div>
      {icon && <div className="absolute top-0 right-0 mr-4 mt-4">{icon}</div>}
      {helperText && (
        <p className="text-sm text-lightgray-400 leading-tight mt-2">
          {helperText}
        </p>
      )}
      {hasError && !meta.active && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          color: #616161;
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #443eff;
        }
        input:focus + .inputLabel {
          color: #443eff;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
        input::placeholder {
          color: #616161;
          opacity: 1;
        }
      `}</style>
    </div>
  )
}

export default TextInput
