import LinearProgress from "@material-ui/core/LinearProgress"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { makeStyles } from "@material-ui/styles"
import api from "service/api"
import Dropdown from "components/Inputs/Select"
import InputField from "components/Inputs/TextField"
import {
  leadCaptureFormHighestEduOptionInlineForm,
  leadCaptureFormIeltsOptions,
  leadCaptureFormIntakeOptions,
  leadCaptureFormProgramOptions,
} from "DataMapper/Profile/options"
import cloneDeep from "lodash/cloneDeep"
import Image from "next/image"
import { useRouter } from "next/router"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { Field, Form } from "react-final-form"
import { toast } from "react-toastify"

import { navRoutes } from "page_routes"
import { saveLandingPageFormV2 } from "./countryPageFormApi"

import { LoginProps, withLogin } from "components/Login/withLogin"
import { trackClick, trackForm, trackPage } from "scripts/segment"
import { getUtmData } from "utils/utm_utils"
//new
import CustomInputSelect from "components/Inputs/CustomInputSelect"
import { logEvent } from "ga"
import styles from "public/static/scss/LeadCaptureLayout.module.scss"
import { LeadCaptureStepsPage } from "utils/events-utils"

interface FormObject {
  [key: string]: string
}

interface Props extends LoginProps {
  country?: string
  isLoggedIn?: boolean
  formData: FormObject
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "transparent",
      height: "12px",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#7FBF7D",
      height: "12px",
    },
  },
})

const selectedCourseValueTextMapper: { [key: string]: string } = {
  POST_GRADUATE_DIPLOMA: "PG Diploma",
  MASTERS: "Masters",
  UNDER_GRADUATION: "UG course",
  MBA: "MBA",
  PHD: "PHD",
  NOT_DECIDED: "Course not decided",
}

const selectedIntakeValueTextMapper: { [key: string]: string } = {
  SEPTEMBER_2021: "Sept 2021",
  JANUARY_2022: "Jan 2022",
  YEAR_2024: "Year 2024",
  YEAR_2023: "2023",
  JAN_2023: "Jan 2023",
  SEP_2023: "Sept 2023",
}

const formViewProgressMapper: { [key: number]: number } = {
  1: 5, //viewNumber : percentange
  2: 20,
  3: 40,
  4: 60,
  5: 80,
  6: 95,
}

const CustomLandingPageForm: FunctionComponent<Props> = ({
  country,
  isLoggedIn,
  formData,
  initLogin,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const [existingUser, setExistingUser] = useState<boolean>(false)
  const [currentFormStep, setCurrentFormStep] = useState(1)
  const [intakeNudgeData, setIntakeNudgeData] = useState<any>()

  useEffect(() => {
    fetchNudgeData()
    router.prefetch(navRoutes.SPOT_COUNSELLING_CALENDER)
  }, [])

  const fetchNudgeData = async () => {
    try {
      const res = await api.get(
        `/api/scholarRoute?path=internal-profile/get/intake-nudge`,
      )
      if (res?.data?.success) {
        setIntakeNudgeData(res?.data?.data)
      } else {
        throw new Error(res?.data?.message)
      }
    } catch (err: any) {
      return err.message || "Something went wrong"
    }
  }

  const redirectToMeetingPage = (formId: string) => {
    router.push({
      pathname: navRoutes.MEETING_FINDER,
      query: { ...router.query, formId },
    })
  }
  const returnCountry = (country: string) => {
    switch (country) {
      case "UK":
        return "UK"
      case "CANADA":
        return "Canada"
      case "USA":
        return "USA"
      case "AUSTRALIA":
        return "Australia"
      case "IRELAND":
        return "Ireland"
      default:
        return "Other"
    }
  }

  const onSubmit = async (values: any) => {
    if (currentFormStep == 6) {
      trackClick(LeadCaptureStepsPage.STEP_SIX, {
        contentName: "Next",
        contentFormat: "Button",
      })
      logEvent("Country page form submit", `${country}`, "")
      const utmDetails = getUtmData(null)
      const newValues = cloneDeep(values)
      newValues.utmSource = utmDetails?.utmSource || ""
      newValues.utmCampaign = utmDetails?.utmCampaign || ""
      newValues.gclid = utmDetails?.gclid || ""
      newValues.adName = utmDetails?.adName || ""
      newValues.utmMedium = utmDetails?.utmMedium || ""
      newValues.utmTerm = utmDetails?.utmTerm || ""
      newValues.formId = "inpage_form"
      newValues.campaignType = utmDetails?.campaignType || ""
      newValues.adId = utmDetails?.adId || ""
      newValues.preferredCountry = returnCountry(country as string)

      if (newValues && newValues.highestLevelEducation) {
        if (newValues.grades) {
          if (newValues.grades > 10) {
            newValues.gradeMetric = "PERCENTAGE"
          } else {
            newValues.gradeMetric = "CGPA"
          }
        }
      }
      const trackPayload = {
        formType: "inpage",
        formId: "inpage_form",
        ...newValues,
      }
      trackForm("Form Submitted", trackPayload)

      try {
        const res = await saveLandingPageFormV2(newValues)
        if (res) {
          if (isLoggedIn) {
            redirectToMeetingPage(newValues.formId)
          } else {
            initLogin(
              "Sign Up To Get Free Counselling",
              true,
              newValues.phone,
              "counselling",
              () => redirectToMeetingPage(newValues.formId),
            )
          }
        } else {
          toast.error("Something went wrong. Please submit the form later")
        }
      } catch (err: any) {
        setExistingUser(true)
        setTimeout(() => {
          initLogin(
            "Sign Up To Get Free Counselling",
            true,
            newValues.phone,
            "counselling",
            () => window.open(navRoutes.PROFILE, "_self"),
          )
        }, 3000)
      }
    } else {
      if (currentFormStep == 1) {
        trackClick(LeadCaptureStepsPage.STEP_ONE, {
          contentName: "Next",
          contentFormat: "Button",
        })
      } else if (currentFormStep == 2) {
        trackClick(LeadCaptureStepsPage.STEP_TWO, {
          contentName: "Next",
          contentFormat: "Button",
        })
      } else if (currentFormStep == 3) {
        trackClick(LeadCaptureStepsPage.STEP_THREE, {
          contentName: "Next",
          contentFormat: "Button",
        })
      } else if (currentFormStep == 4) {
        trackClick(LeadCaptureStepsPage.STEP_FOUR, {
          contentName: "Next",
          contentFormat: "Button",
        })
      } else if (currentFormStep == 5) {
        trackClick(LeadCaptureStepsPage.STEP_FIVE, {
          contentName: "Next",
          contentFormat: "Button",
        })
      }

      setCurrentFormStep((step) => step + 1)
    }
  }

  const getCtaText = useCallback(() => {
    switch (currentFormStep) {
      case 1:
        return formData.ctaCopy || "Get Started"
      case 2:
      case 3:
      case 4:
      case 5:
        return "NEXT"
      case 6:
        return "COMPLETE"
      default:
        return "NEXT"
    }
  }, [currentFormStep])

  useEffect(() => {
    if (currentFormStep == 1) {
      trackPage(LeadCaptureStepsPage.STEP_ONE)
    } else if (currentFormStep == 2) {
      trackPage(LeadCaptureStepsPage.STEP_TWO)
    } else if (currentFormStep == 3) {
      trackPage(LeadCaptureStepsPage.STEP_THREE)
    } else if (currentFormStep == 4) {
      trackPage(LeadCaptureStepsPage.STEP_FOUR)
    } else if (currentFormStep == 5) {
      trackPage(LeadCaptureStepsPage.STEP_FIVE)
    } else if (currentFormStep == 6) {
      trackPage(LeadCaptureStepsPage.STEP_SIX)
    }
  }, [currentFormStep])

  const SelectedCourseInfoCard = (props: { course: string; year: string }) => {
    const { course, year } = props || {}
    return (
      <div
        className={`bg-green-500 md:py-3 py-2 md:px-4 px-3 mx-auto md:mb-6 mb-4 ${styles.courseBadge}`}
      >
        <img
          src= {`${process.env.CDN_URL}/assets/images/lead-capture/university-cap.svg`}
          className="inline pb-1"
        />
        <span className="text-white font-bold md:text-font18 text-base md:pl-2 pl-1">
          {selectedCourseValueTextMapper[course]},{" "}
          {selectedIntakeValueTextMapper[year]}
        </span>
      </div>
    )
  }

  return (
    <div className={styles.wrapperLeadCapture}>
      <div className={`${classes.root}`}>
        {currentFormStep == 2 && (
          <div className="bg-primary py-4">
            <p className="text-center text-white text-sm font-bold">
              September 2023 intakes are now open! Apply Now.
            </p>
          </div>
        )}
        <LinearProgress
          variant="determinate"
          value={formViewProgressMapper[currentFormStep]}
        />
      </div>
      {currentFormStep > 1 && (
        <div
          className="max-w-row mx-auto text-gray-1741 flex items-center pt-4 md:pl-0 pl-2 cursor-pointer"
          onClick={() => {
            setExistingUser(false)
            setCurrentFormStep((step) => step - 1)
          }}
        >
          <ArrowBackIcon />
          <p className="text-font16 hidden md:inline-block pl-2">BACK</p>
        </div>
      )}
      <div
        className={`flex flex-col justify-between ${styles.wrapperLeadCapture}`}
      >
        <div className={styles.fieldsContainer}>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, values, submitting }) => {
              return (
                <div>
                  <form onSubmit={handleSubmit} noValidate>
                    {currentFormStep == 1 && (
                      <div className="flex flex-col items-center mt-16 md:p-0 pl-4 pr-4">
                        <p className="text-violet-300 text-center md:text-font32 text-font22 px-2 md:px-6 font-bold mt-4">
                          {formData.headline ||
                            "Start your journey with the best Study Abroad Experts in India"}
                        </p>
                        <p
                          className="text-center text-black-170 md:text-font20 text-font15 pt-1"
                          style={{ paddingTop: "32px" }}
                        >
                          {formData.subHeading ||
                            "Get your profile reviewed by a top Study Abroad Counsellor"}
                        </p>
                        <div className="md:px-7 md:py-2 px-1 py-2 pt-1">
                          <div className="text-font18 md:text-base flex flex-col py-4">
                            {formData.bulletPoints?.length > 0 &&
                              formData.bulletPoints
                                .split("\n")
                                ?.map((point: string, index: number) => (
                                  <div
                                    key={index}
                                    className="flex justify-start items-center pt-1"
                                  >
                                    <div className="flex justify-start items-center">
                                      <Image
                                        src= {`${process.env.CDN_URL}/assets/icons/tick.svg`}
                                        width={30}
                                        height={25}
                                        layout="fixed"
                                      />
                                    </div>
                                    <div className="md:text-black pl-4 py-1 md:text-font18 text-font14">
                                      {point}
                                    </div>
                                  </div>
                                ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {currentFormStep == 2 && (
                      <div>
                        <div className="md:mt-6 mt-4">
                          <p className="text-center text-gray-170 md:text-font24 font-bold">
                            {formData.preferredProgramQuestion ||
                              "What degree do you wish to pursue?"}
                          </p>
                          <div className="md:px-7 md:py-2 px-1 py-2">
                            <Field
                              name="preferredProgram"
                              label="Preferred Program"
                              component={CustomInputSelect}
                              options={leadCaptureFormProgramOptions}
                              isMultiSelect={false}
                              validate={(v) => {
                                if (!v) return "Select preferred program"
                              }}
                              question="Preferred Program"
                              subText=""
                            />
                          </div>
                        </div>
                        <div>
                          <p className="text-center text-gray-170 md:text-font24 font-bold">
                            {formData.preferredIntakeQuestion ||
                              "What intake are you planning to apply in?"}
                          </p>
                          <div className="md:px-7 md:py-2 px-1 py-2">
                            <Field
                              name="preferredIntake"
                              label="Select Intake"
                              component={CustomInputSelect}
                              options={leadCaptureFormIntakeOptions}
                              isMultiSelect={false}
                              validate={(v) => {
                                if (!v) return "Select preferred intake"
                              }}
                              question="Preferred intake"
                              subText=""
                              nudgeOption={
                                intakeNudgeData?.filter(
                                  (elem: any) =>
                                    elem.country === values.preferredCountry,
                                )[0]
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {currentFormStep == 3 && (
                      <div>
                        <SelectedCourseInfoCard
                          course={values.preferredProgram}
                          year={values.preferredIntake}
                        />
                        <div>
                          <p className="text-center text-gray-170 md:text-font24 text-font16 font-bold">
                            What’s your highest level of education?
                          </p>
                          <div className="md:px-7 md:py-2 px-1 py-2 ">
                            <Field
                              name="highestLevelEducation"
                              component={CustomInputSelect}
                              placeholder="Highest Education*"
                              label="Highest Education*"
                              options={
                                leadCaptureFormHighestEduOptionInlineForm
                              }
                              validate={(v) => {
                                if (!v)
                                  return "Please select your highest education"
                              }}
                            />
                          </div>
                        </div>

                        {values && values.highestLevelEducation && (
                          <div className="md:mt-6 mt-4">
                            <p className="text-center text-gray-170 md:text-font24 text-font16 font-bold">
                              Expected or Gained Percentage
                            </p>
                            <div className="md:px-7 md:py-2 px-4 py-2 w-full md:w-4/5 md:mx-auto">
                              <Field
                                name="grades"
                                component={InputField}
                                placeholder="Grades*"
                                label="Grades"
                                type="number"
                                validate={(v) => {
                                  if (!v) {
                                    return "This is a mandatory field"
                                  }
                                  if (v < 1 || v > 100) {
                                    return "Enter number between 1 to 100"
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {/* <div>
                            <p className="text-center text-gray-170 md:text-font24 font-bold">
                              Year of graduation of your highest education
                            </p>
                            <div className="md:px-7 md:py-2 px-4 py-2 w-full md:w-4/5 md:mx-auto">
                              <Field
                                name="highestEducationPassoutYear"
                                component={Dropdown}
                                placeholder="Year"
                                // label="Study Abroad Journey Stage"
                                options={highestEducationPassoutYear}
                                validate={(v) => {
                                  if (!v) return "This is a mandatory field";
                                }}
                                isSearchable={false}
                              />
                            </div>
                          </div> */}
                      </div>
                    )}

                    {currentFormStep == 4 && (
                      <div>
                        <SelectedCourseInfoCard
                          course={values.preferredProgram}
                          year={values.preferredIntake}
                        />

                        <div className="md:mt-6 mt-4">
                          <p className="text-center text-gray-170 md:text-font24 text-font16 font-bold">
                            IELTS/TOEFL/PTE/Duolingo status
                          </p>
                          <div className="md:px-7 md:py-2 px-4 py-2 w-full md:w-4/5 md:mx-auto">
                            <Field
                              name="ieltsStatus"
                              placeholder="Status of your exam*"
                              component={Dropdown}
                              options={leadCaptureFormIeltsOptions}
                              validate={(v) => {
                                if (!v) return "This is a mandatory field"
                              }}
                              isSearchable={false}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {currentFormStep == 5 && (
                      <div>
                        <SelectedCourseInfoCard
                          course={values.preferredProgram}
                          year={values.preferredIntake}
                        />
                        <div>
                          <p className="text-center text-gray-170 md:text-font24 font-bold">
                            Please enter your Name and Email
                          </p>
                          <div className="md:px-7 md:py-2 px-4 py-2 w-full md:w-4/5 md:mx-auto">
                            <Field
                              name="name"
                              label="Name*"
                              component={InputField}
                              validate={(v) => {
                                if (!v) return "This is a mandatory field"
                              }}
                            />
                          </div>
                        </div>
                        <div className="md:mt-4 mt-2">
                          <div className="md:px-7 md:py-2 px-4 py-2 w-full md:w-4/5 md:mx-auto">
                            <Field
                              name="email"
                              label="Email Address*"
                              component={InputField}
                              validate={(v) => {
                                if (
                                  !/^undefined$|^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                    v,
                                  )
                                ) {
                                  return "Please enter a valid email"
                                }
                                if (!v) return "This is a mandatory field"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {currentFormStep == 6 && (
                      <div>
                        <SelectedCourseInfoCard
                          course={values.preferredProgram}
                          year={values.preferredIntake}
                        />
                        <div>
                          <div className="px-8">
                            <p className="text-center text-gray-170 md:text-font24 font-bold">
                              Congratulations!
                            </p>

                            <p className="text-gray-170 text-center mt-6">
                              You have taken the first step, Lets book a free
                              counselling session for you.
                            </p>

                            <div className="flex justify-center mt-8">
                              <img
                                src= {`${process.env.CDN_URL}/assets/icons/university_logo.svg`}
                                alt="icon"
                                className="mr-4"
                              />
                              <p className="text-center text-gray-170 md:text-lg text-sm pt-2">
                                Get a personalised university shortlist
                              </p>
                            </div>

                            <div className="flex justify-center mt-4">
                              <img
                                src= {`${process.env.CDN_URL}/assets/icons/chat_icon.svg`}
                                alt="icon"
                                className="mr-4"
                              />
                              <p className="text-center text-gray-170 md:text-lg text-sm pt-2">
                                Get answers to all your doubts from an expert
                              </p>
                            </div>

                            <div className="flex justify-center mt-4">
                              <img
                                src= {`${process.env.CDN_URL}/assets/icons/checkpoint_icon.svg`}
                                alt="icon "
                                className="mr-4"
                              />
                              <p className="text-center text-gray-170 md:text-lg text-sm pt-2">
                                Get a free checklist to plan your study abroad
                                dream
                              </p>
                            </div>
                          </div>

                          <p className="text-center text-gray-170 md:text-font22 font-bold mt-8 mb-2">
                            Enter your Mobile Number
                          </p>

                          <div className="md:px-7 md:py-2 px-4 py-2 w-full md:w-4/5 md:mx-auto">
                            <Field
                              name="phone"
                              label="Phone*"
                              type="number"
                              component={InputField}
                              validate={(v) => {
                                if (!v) return "Please enter your phone number"
                                if (v.length !== 10)
                                  return "Please enter 10 digit phone number"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                  <div className="w-full flex justify-center">
                    {existingUser ? (
                      <p className="md:text-base text-font14 pb-4 text-center text-red-600">
                        User already exists. Redirecting to profile page ...
                      </p>
                    ) : (
                      <button
                        className="px-8 bg-primary py-3 rounded text-white flex justify-center mx-auto my-4"
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        <p className="pr-4 md:text-font16">
                          {submitting ? "SAVING..." : getCtaText()}
                        </p>
                        {!submitting && <ArrowForwardIcon />}
                      </button>
                    )}
                  </div>
                </div>
              )
            }}
          </Form>
        </div>
        <img
          src= {`${process.env.CDN_URL}/assets/images/lead-capture/background-illustration.svg`}
          className="bottom-0 hidden md:block -z-1 md:absolute"
        />
        <img
          src= {`${process.env.CDN_URL}/assets/images/lead-capture/background-illustration-mobile.svg`}
          className="bottom-0 md:hidden w-full -z-1"
        />
      </div>
    </div>
  )
}
export default withLogin(CustomLandingPageForm)
