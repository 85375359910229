import api from "service/api"
import { NextPage, NextPageContext } from "next"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
//utils
import { getUserDataAndStatus } from "components/TofuCommonApis/checkforUserStatus"
import { navRoutes } from "page_routes"
import { trackPage } from "scripts/segment"
import { pageName } from "utils/events-utils"
// HOC
import Layout from "components/common/Layout"
import withAuth, { AuthProps } from "components/decorator/WithAuth"
// Components
import CustomLandingPageForm from "components/NewLandingPage/sections/CustomLandingForm"
import SemLandingPagesHead from "components/TofuCommonComponents/SemLandingPagesHead"
import TofuLoaderV2 from "components/TofuCommonComponents/TofuLoaderV2"
import Error from "next/error"

type SEMProps = AuthProps & { pageDataServerSide: any }

const SEMPage: NextPage<SEMProps> = ({ auth, pageDataServerSide }) => {
  const isLoggedIn = auth && auth.isValid
  const router = useRouter()
  const [selectedCountry, setSelectedCountry] = useState<string>("")
  const [formData, setFormData] = useState<FormObject>({})
  const [isRedirectionLoading, setIsRedirectionLoading] =
    useState<Boolean>(false)
  const [redirectionText, setRedirectionText] = useState<any>({
    mainText: "",
    subText: "",
  })

  useEffect(() => {
    if (router.query?.country != undefined && router.query?.type != undefined) {
      trackPage(pageName.Form_Landing_page)
      setSelectedCountry(router.query.country as string)
      if (pageDataServerSide && pageDataServerSide.length > 0) {
        setFormData(pageDataServerSide[0])
      }
    }

    if (isLoggedIn) {
      handleLoggedInRedirect()
    }
  }, [])

  const handleLoggedInRedirect = async () => {
    setIsRedirectionLoading(true)
    const res = await getUserDataAndStatus()
    if (
      res.events.length > 0 &&
      res.events[0].eventCategory === "SPOT_COUNSELLING"
    ) {
      setRedirectionText({
        mainText: "You have already booked a meeting!",
        subText: "Taking you to your dashboard now...",
      })
      setTimeout(() => {
        window && window.open(navRoutes.COUNSELLING_OVERVIEW, "_self")
      }, 4000)
    } else if (res.status) {
      if (res.qualifiedSlot === 1 || res.qualifiedSlot === 2) {
        setRedirectionText({
          mainText: "You can schedule a free counselling session!",
          subText: "Redirecting you to calendar page...",
        })
        setTimeout(() => {
          window && window.open(navRoutes.SPOT_COUNSELLING_CALENDER, "_self")
        }, 4000)
      } else {
        setIsRedirectionLoading(false)
      }
    } else {
      setIsRedirectionLoading(false)
    }
  }

  return (
    <>
      <SemLandingPagesHead selectedCountry={selectedCountry.toUpperCase()} />
      {isRedirectionLoading ? (
        <TofuLoaderV2
          mainText={redirectionText?.mainText}
          subText={redirectionText?.subText}
        />
      ) : (
        <Layout showConnect={false} isFooterHidden={true}>
          {formData && Object.keys(formData).length > 0 ? (
            <CustomLandingPageForm
              isLoggedIn={isLoggedIn}
              country={selectedCountry.toUpperCase() as string}
              formData={formData as FormObject}
            />
          ) : (
            <Error statusCode={404} />
          )}
        </Layout>
      )}
    </>
  )
}

SEMPage.getInitialProps = async (ctx: NextPageContext) => {
  try {
    const res = await api.get(
      process.env.CMS_URL +
        `/sem-questions?version=v2&country=${ctx.query.country}&type=${ctx.query.type}`,
    )

    if (!res.data || (Array.isArray(res.data) && !res.data?.length)) {
      return {
        pageDataServerSide: null,
        error: {
          statusCode: 404,
        },
      }
    }

    return {
      pageDataServerSide: res.data,
    }
  } catch (err) {
    return {
      pageDataServerSide: null,
      error: {
        statusCode: 404,
      },
    }
  }
}

export default withAuth(SEMPage, false)

interface FormObject {
  [key: string]: string
}
